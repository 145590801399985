import { Box, Button, FormControl, Stack, TextField, ThemeProvider, Typography } from "@mui/material";
import { useState } from "react";
import theme from "../theme";

const WaitlistForm = () => {
    const [fullName, setFullName] = useState("");
    const [fullNameError, setFullNameError] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [companyNameError, setCompanyNameError] = useState(null);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(null);

    const [submitting, setSubmitting] = useState(false);
    const [formWasSubmit, setFormWasSubmit] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let isValid = true;

        if (fullName.trim() === "") {
            setFullNameError("Full name is required.");
            isValid = false;
        } else {
            setFullNameError(null);
        }

        if (companyName.trim() === "") {
            setCompanyNameError("Company name is required.");
            isValid = false;
        } else {
            setCompanyNameError(null);
        }

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            isValid = false;
        } else {
            setEmailError(null);
        }

        if (!isValid) return;

        setSubmitting(true);

        try {
            const formData = new URLSearchParams();
            formData.append("entry.1846642734", fullName); // Replace with actual field IDs
            formData.append("entry.1981922152", companyName); // Replace with actual field IDs
            formData.append("emailAddress", email); // Replace with actual field IDs

            const response = await fetch("https://docs.google.com/forms/u/0/d/e/1FAIpQLScpP7hIFyOpHllNvj2AXNSsNkyf-QwYctJgq2lIAJtxONf29w/formResponse", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: formData.toString(),
                mode: "no-cors",
            });

            setFormWasSubmit(true);

        } catch (error) {
            alert(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            {formWasSubmit ? (
                <Box
                    sx={{ alignItems: "center", alignContent: "center", textAlign: "center", width: "100%" }}
                >
                    <Typography variant="body1" sx={{ marginBottom: 4 }}>
                        <u>Thanks for signing up!</u>
                    </Typography>
                </Box>
            ) : (
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    style={{ width: "100%" }}
                >
                    <FormControl sx={{ width: "100%" }}>
                        <Stack sx={{ width: "100%" }} spacing={2}>
                            <TextField
                                size="small"
                                label="Full name"
                                variant="standard"
                                color="primary"
                                fullWidth
                                type="text"
                                required
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                error={!!fullNameError}
                                helperText={fullNameError}
                            />
                            <TextField
                                size="small"
                                label="Company"
                                variant="standard"
                                color="primary"
                                fullWidth
                                type="text"
                                required
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                error={!!companyNameError}
                                helperText={companyNameError}
                            />
                            <TextField
                                size="small"
                                label="Email"
                                variant="standard"
                                color="primary"
                                fullWidth
                                type="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={!!emailError}
                                helperText={emailError}
                            />
                            <Button
                                size="small"
                                variant="contained"
                                fullWidth
                                color="secondary"
                                type="submit"
                                disabled={submitting}
                            >
                                {submitting ? "Submitting..." : "Join Waitlist"}
                            </Button>
                        </Stack>
                    </FormControl>
                </form>
            )}
        </ThemeProvider>
    );
};

export default WaitlistForm;
