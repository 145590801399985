import React from 'react';
import {
	Box, Typography, Stack
} from '@mui/material';

import TwoDotLogo from './assets/Two_Dot_pink.png';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import WaitlistForm from './Components/WaitlistForm';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					minHeight: "100vh",
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					background: 'white',
				}}
			>
				<Box
					sx={{
						background: 'white',
						border: `8px solid ${theme.palette.primary.main}`,
						width: "85%",
						maxWidth: "1600px",
						minHeight: "90vh",
						borderRadius: "32px",
						padding: 4,
						margin: 4,
						boxSizing: 'border-box', // Ensure padding is included in the size
						overflow: 'hidden',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end'
					}}
					boxShadow={8}
				>
					<Stack
						direction={{ md: 'column', lg: 'row' }}
						sx={{
							minHeight: "100%",
							flexWrap: 'wrap',
							width: '100%',
							justifyContent: 'flex-end',
							alignItems: 'stretch',
							gap: 4, // Ensure consistent spacing
						}}
					>
						<Stack
							sx={{
								flex: { lg: 1 },
								justifyContent: 'flex-end',
							}}
							spacing={2}
						>
							<Box sx={{ height: '192px' }}>
								<img src={TwoDotLogo} height={192} alt="Two Dot Logo" />
							</Box>
							<Typography variant="h1">Win More Clients with Data-Driven Insights</Typography>
							<Typography variant="subtitle1">Uncover companies hiring in your niche and grow your revenue with our business development platform built for recruiters.</Typography>
							<Typography variant="body1">
								Our platform simplifies your business development by instantly identifying companies that actively hiring or likely to hire in your niche. By considering your network and how their
								mission aligns with your expertise, you get access to actionable insights, warm introductions, and hyper-personalised outreach.
							</Typography>
							<Typography variant="body1">
								Build stronger client relationships and close deals faster—all while maximising your revenue potential.
							</Typography>
						</Stack>

						<Box
							sx={{
								flex: { lg: 0.25 },
								width: '100%',
								display: 'flex',
								alignItems: 'flex-end',
							}}
						>
							<WaitlistForm />
						</Box>
					</Stack>
				</Box>
			</Box>
		</ThemeProvider>
	);
}

export default App;